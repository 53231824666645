import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: { name: 'restocking' },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '/restocking', component: () => import('pages/ProductsPage.vue'), name: 'restocking' },
      { path: '/inventory', component: () => import('pages/InventoryPage.vue'), name: 'inventory' },
      { path: '/login', component: () => import('pages/LoginPage.vue'), name: 'login' }
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
];

export default routes;
